import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { delay } from 'rxjs/operators';
export class Data {
  constructor(
    public cartList: any[],
    public mercadoPagoTotalPrice: number,
    public totalPrice: number
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public navigateFromHeader: boolean;
  public navigateCategory: string;

  public Data = new Data(
    [],  // cartList
    null, // totalPrice
    null, // TotalMercadoPago
  );
  WpEcommercePostEnpoint = environment.WpEcommercePostEnpoint;
  Uncategorized = 1;
  auriculares = 3;
  tablet = 4;
  portatiles = 2;
  camaras = 5;
  offers = 6;
  telefonos = 7;
  otros = 8;
  almacenamiento = 9;
  monitores = 10;
  placasDeVideo = 11;
  motherboards = 12;
  procesadores = 13;
  fuentes = 14;
  memorias = 15;
  gabinetes = 16;
  perifericos = 17;
  public url = "assets/dataTest/";

  WpEcommercePagesEnpoint = environment.WpEcommercePagesEnpoint;
  carrouselPageId = 203;

  public totalPrice = 0;
  constructor(public http: HttpClient) { }


  // For now i will get from the api, the post with no type
  // removing per_page: '9' attribute
  public getProductsHome(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '15', categories: `${this.Uncategorized}`, observe: 'response' } });
  }

  public getProducts(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.Uncategorized}`, observe: 'response' } });
  }


  public getCategoryAuriculares(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.auriculares}`, observe: 'response' } });
  }

  public getCategoryTablet(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.tablet}`, observe: 'response' } });
  }
  public getCategoryPortatiles(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.portatiles}`, observe: 'response' } });
  }

  public getCategoryOffers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.offers}`, observe: 'response' } });
  }
  public getCategoryCameras(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.camaras}`, observe: 'response' } });
  }
  public getCategoryTelefonos(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.telefonos}`, observe: 'response' } });
  }
  public getCategoryPerifericos(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.perifericos}`, observe: 'response' } });
  }

  public getCategoryAlmacenamiento(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.almacenamiento}`, observe: 'response' } });
  }

  public getCategoryMonitores(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.monitores}`, observe: 'response' } });
  }

  public getCategoryPlacasDeVideo(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.placasDeVideo}`, observe: 'response' } });
  }
  public getCategoryMotherboards(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.motherboards}`, observe: 'response' } });
  }

  public getCategoryProcesadores(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.procesadores}`, observe: 'response' } });
  }

  public getCategoryFuentes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.fuentes}`, observe: 'response' } });
  }

  public getCategoryMemorias(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.memorias}`, observe: 'response' } });
  }

  public getCategoryGabinetes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.gabinetes}`, observe: 'response' } });
  }

  public getCategoryOtros(): Observable<any[]> {
    return this.http.get<any[]>(`${this.WpEcommercePostEnpoint}`,
      { params: { per_page: '100', categories: `${this.otros}`, observe: 'response' } });
  }
  /*
  
  */
  public getProductById(id: any): Observable<any> {
    return this.http.get<any>(`${this.WpEcommercePostEnpoint}`, { params: { include: `${id}`, observe: 'response' } });
  }

  public getAllPages(): Observable<any> {
    return this.http.get<any>(`${this.WpEcommercePagesEnpoint}`, { params: { include: `${this.carrouselPageId}`, observe: 'response' } });
  }

  public addToCart(product: any) {
    let message;
    let status;
    this.Data.totalPrice = null;
    this.Data.cartList.push(product);
    this.Data.cartList.forEach(product => {
      if (product.acf.precio_actual) {
        this.Data.totalPrice = this.Data.totalPrice + parseInt(product.acf.precio_actual, 10);
        // console.log(product.acf.precio_mercadopago);
        this.Data.mercadoPagoTotalPrice = this.Data.mercadoPagoTotalPrice + parseInt(product.acf.precio_mercadopago, 10);
      } else {
        this.Data.totalPrice = 0;
      }
    });

    /*
    if (this.Data.cartList.filter(item => item.id === product.id)[0]) {
      message = 'The product ' + product.name + ' already added to cart.';
      status = 'error';
    } else {
      */
    // message = 'The product ' + product.name + ' has been added to cart.';
    // status = 'success';
  }
  // this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
}
