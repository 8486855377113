import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, AfterViewInit, Renderer2, QueryList, Renderer } from '@angular/core';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { FormGroup } from '@angular/forms';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import * as $ from 'jquery';
import { AlertService } from 'src/app/services/alertService/alertService';
@Component({
  selector: 'app-arma-tu-pc',
  templateUrl: './arma-tu-pc.component.html',
  styleUrls: ['./arma-tu-pc.component.scss']
})
export class ArmaTuPCComponent implements OnInit, AfterViewInit {
  public products: Array<any> = [];
  public productId;
  public product;
  public noFilter = true;
  public loading = true;
  public formNouiSlider: FormGroup;
  public noProducts: boolean = false;
  /* Nouislider */
  public filterPriceActive = true;
  public filteredPrice: Array<any> = [];
  /*Filter by Brand */
  public brandNames: Array<any> = [];
  public filterBrand: Array<any> = [];
  public filterBrandActive = false;
  public brandName: string;
  // Img Spinner
  public loadingImg = true;
  public loadingDescriptionImg = false;
  // Firebase Db
  public itemsRef: AngularFireList<any>;
  public rankingProductsItems: Observable<any[]>;
  public storedrankingProductsArray: Array<any> = [];
  public filteredItems: Array<any> = [];
  // Stars Array
  public oneStar: Array<any> = [];
  public twoStar: Array<any> = [];
  public threeStar: Array<any> = [];
  public fourStar: Array<any> = [];
  public fiveStar: Array<any> = [];
  // Slider
  public productSlideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 2,
    "autoplay": true,
    "autoplaySpeed": 2000
  };
  // Mobile
  public mobileText;

  /* Pagination */
  public page: number = 1;
  public pageSize: number = 3;

  /* Navigation Build */
  @ViewChild('motherboard', { static: false }) motherboard: ElementRef;
  @ViewChild('memoria', { static: false }) memoria: ElementRef;
  @ViewChild('almacenamiento', { static: false }) almacenamiento: ElementRef;
  @ViewChild('placasdevideo', { static: false }) placasdevideo: ElementRef;
  @ViewChild('fuente', { static: false }) fuente: ElementRef;
  @ViewChild('gabinete', { static: false }) gabinete: ElementRef;
  @ViewChild('perifericos', { static: false }) perifericos: ElementRef;

  constructor(public productsService: ProductsService,
    public db: AngularFireDatabase,
    public alertService: AlertService,
    public renderer: Renderer
  ) {

    // Firebase db
    this.itemsRef = db.list('productRanking');
    this.rankingProductsItems = this.itemsRef.valueChanges();

  }

  ngOnInit() {
    this.getCategories('procesadores');
    if ($(window).width() < 960) {
      this.mobileText = true;
      this.productSlideConfig = {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000
      };
    }


  }
  ngAfterViewInit(): void {

    /* Listen navigation element and changing visibility of the icons */
    this.renderer.listen(this.motherboard.nativeElement, 'click', (event) => {

      this.motherboard.nativeElement.lastElementChild.style.visibility = 'visible';
    });
    this.renderer.listen(this.memoria.nativeElement, 'click', (event) => {

      this.memoria.nativeElement.lastElementChild.style.visibility = 'visible';
    });
    this.renderer.listen(this.almacenamiento.nativeElement, 'click', (event) => {

      this.almacenamiento.nativeElement.lastElementChild.style.visibility = 'visible';
    });
    this.renderer.listen(this.placasdevideo.nativeElement, 'click', (event) => {

      this.placasdevideo.nativeElement.lastElementChild.style.visibility = 'visible';
    });
    this.renderer.listen(this.fuente.nativeElement, 'click', (event) => {

      this.fuente.nativeElement.lastElementChild.style.visibility = 'visible';
    });
    this.renderer.listen(this.gabinete.nativeElement, 'click', (event) => {

      this.gabinete.nativeElement.lastElementChild.style.visibility = 'visible';
    });

    this.renderer.listen(this.perifericos.nativeElement, 'click', (event) => {

      this.perifericos.nativeElement.lastElementChild.style.visibility = 'visible';
    });


  }



  public getProduct(id: any) {
    let category;
    const temArray = [];
    this.product = {};
    let tempObj;
    this.loadingDescriptionImg = true;
    this.productsService.getProductById(id).subscribe(res => {
      if (res[0].acf.imagen1 === false) {
        res[0].acf.imagen1 = undefined;
      }
      if (res[0].acf.imagen2 === false) {
        res[0].acf.imagen2 = undefined;
      }
      if (res[0].acf.imagen3 === false) {
        res[0].acf.imagen3 = undefined;
      }
      res[0]._embedded['wp:term'][0].forEach(element => {
        if (element.name !== 'Ofertas' && element.name !== 'Uncategorized') {
          temArray.push(element);
        }
      });
      tempObj = temArray.pop();
      category = tempObj.name.toLowerCase().normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '');
      // this.categoryName = category;
      this.product = res[0];
      this.productId = res[0].id;

      this.storedrankingProductsArray = [];
      this.getRating();
      /* Removing the elements with rendered2 */

      // $("#reviewsCol").innerText = '';
      this.loadingDescriptionImg = false;
      //  this.getRelatedProducts(this.categoryName);
      setTimeout(() => {
        this.loading = false;
      }, 800);
    });
  }
  /*Rating Methods from Firebase DB */
  public getRating() {
    this.filteredItems = [];
    this.oneStar = [];
    this.twoStar = [];
    this.threeStar = [];
    this.fourStar = [];
    this.fiveStar = [];
    this.filteredItems.length = 0;
    this.rankingProductsItems.subscribe(array => {
      array.forEach(res => {
        this.storedrankingProductsArray.push(res);
      });
      this.storedrankingProductsArray.map(res => {
        if (res.productId === this.productId) {

          this.filteredItems.push(res);
          switch (res.stars) {
            case '1': this.oneStar.push(res.stars);
              break;
            case '2': this.twoStar.push(res.stars);
              break;
            case '3': this.threeStar.push(res.stars);
              break;
            case '4': this.fourStar.push(res.stars);
              break;
            case '5': this.fiveStar.push(res.stars);
              break;
          }
        }
      });
    });
  }

  public getCategories(productCategories) {
    switch (productCategories) {
      case 'procesadores':
        this.loadingImg = true;
        this.products = [];
        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryProcesadores().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'motherboard':
        this.loadingImg = true;
        this.products = [];

        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryMotherboards().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'almacenamiento':
        this.loadingImg = true;

        this.products = [];
        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryAlmacenamiento().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });


            this.loading = false;
          }
        });
        break;
      case 'placas-de-video':
        this.loadingImg = true;
        this.products = [];

        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryPlacasDeVideo().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'fuentes':
        this.loadingImg = true;
        this.brandNames = [];
        this.products = [];

        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryFuentes().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });


            this.loading = false;
          }
        });
        break;
      case 'memorias':
        this.loadingImg = true;
        this.products = [];

        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryMemorias().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });

            this.loading = false;
          }
        });
        break;
      case 'gabinetes':
        this.loadingImg = true;
        this.products = [];

        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryGabinetes().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'perifericos':
        this.loadingImg = true;
        this.products = [];
        this.filterPriceActive = false;
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryPerifericos().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            console.log('here');
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                this.products.push(mapItem);
                window.scroll({
                  top: 100,
                  behavior: 'smooth'
                });
                this.product = {};
              }
            });
            this.loading = false;
          }
        });
        break;
    }
  }

  public addToCart(product: any) {
    this.alertService.info('Producto Añadido');
    this.getProduct(product.id);
    this.productsService.addToCart(product);
  }
  public remove(product) {
    const index: number = this.productsService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.productsService.Data.cartList.splice(index, 1);
      this.productsService.Data.totalPrice = this.productsService.Data.totalPrice - parseInt(product.acf.precio_actual, 10);
    }
  }


}
