import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { StoreComponent } from './store/store.component';
import { ProductComponent } from './store/product/product.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ArmaTuPCComponent } from './arma-tu-pc/arma-tu-pc.component';



const routes: Routes = [
//  { path: '', component: HomeComponent },
  // productos id = category
  { path: 'productos/:id', component: StoreComponent },
  // productos id = product id
  { path: 'producto/:id', component: ProductComponent },
  { path: 'ordenes', component: CheckoutComponent },
  { path: 'arma-tu-pc', component: ArmaTuPCComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EcommerceRoutingModule { }
