import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comming-soon-template',
  templateUrl: './comming-soon-template.component.html',
  styleUrls: ['./comming-soon-template.component.scss']
})
export class CommingSoonTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
