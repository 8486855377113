import { Component, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { LazyLoadScriptService } from 'src/app/services/Lazy-Load-script.service/lazy-load-script.service';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { AlertService } from 'src/app/services/alertService/alertService';
import * as $ from 'jquery';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit {
  public products: Array<any> = [];
  public loading: boolean = true;
  public mobileText: boolean = false;
  public sliderWpPage: any = [];
  public productSlideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 2,
    "autoplay": true,
    "autoplaySpeed": 3000
  };
  public slideConfigSlider = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": true,
    "arrows": false,
    "autoplaySpeed": 4000
  };
  public slidesArray = [];
  // Img Spinner
  public loadingImg = true;
  // Firebase data
  public itemsRef: AngularFireList<any>;
  public productsItems: Observable<any[]>;
  public mostSellingProduct: any[] = [];

  @ViewChild("homeTemplateView", { static: true }) homeTemplateView: ElementRef;
  constructor(
    public lazyLoadService: LazyLoadScriptService,
    public productsService: ProductsService,
    public alertService: AlertService,
    public db: AngularFireDatabase) {
    this.itemsRef = db.list('productRanking');
    this.productsItems = this.itemsRef.valueChanges();
  }

  ngOnInit(): void {
    if ($(window).width() < 960) {
      this.mobileText = true;
      this.productSlideConfig = {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 4000
      };
    } else {
    }
    this.productsService.getAllPages().subscribe(res => {
      this.slidesArray = [
        { src: res[0].acf.imagen_de_carrusel_1.url },
        { src: res[0].acf.imagen_de_carrusel_2.url },
        { src: res[0].acf.imagen_de_carrusel_3.url || '' },
      ];
    });

  }


  ngAfterContentInit() {
    this.getAllProducts();
    this.getBestSellers();
  }


  public getAllProducts() {
    this.productsService.getProductsHome().subscribe(data => {
      // console.log(data[0]._embedded['wp:term'][0][0].name);
      //  console.log(data[0]._embedded);
      data.map((mapItem) => {
        if (mapItem.better_featured_image !== null
          && mapItem.better_featured_image !== undefined
          && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
        ) {
        // console.log(mapItem);
          this.products.push(mapItem);

        }

      });
      // console.log(data);
      // this.products = data;
      this.loading = false;

    });
  }

  public addToCart(product: any) {
    this.alertService.info('Producto Añadido');
    this.productsService.addToCart(product);
  }

  public getBestSellers() {
    let tempArray = [];
    this.loading = true;
    this.productsService.getProducts().subscribe((res) => {
      this.productsItems.subscribe((itemArray) => {
        itemArray.map((mfireRes) => {
          res.map((resMap) => {
            if (resMap.id === mfireRes.productId) {
              tempArray.push(resMap);
            }
          });
          this.mostSellingProduct = [...new Set(tempArray)];

        });
      });
      this.loading = false;
    });

  }



}
