import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommingSoonTemplateComponent } from './components/comming-soon-template/comming-soon-template.component';
import { HomeComponent } from './components/ecommerce/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';


const routes: Routes = [
  { path: '', component: CommingSoonTemplateComponent },
 /* {
    path: '', loadChildren: () => import('./components/ecommerce/ecommerce.module')
      .then(m => m.EcommerceModule),
  },
  */
  {
    path: 'usuarios',
    loadChildren: () => import('./components/authentication/auth-module.module')
      .then(m => m.AuthModuleModule),
  },
  {
    path: 'la-empresa',
    loadChildren: () => import('./components/core/core.module')
      .then(m => m.CoreModule),
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
