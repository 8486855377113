import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frequent-question',
  templateUrl: './frequent-question.component.html',
  styleUrls: ['./frequent-question.component.scss']
})
export class FrequentQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
