import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList, AngularFireAction } from '@angular/fire/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/AuthService/authentication.service';

@Component({
  selector: 'app-users-panel',
  templateUrl: './users-panel.component.html',
  styleUrls: ['./users-panel.component.scss']
})
export class UsersPanelComponent implements OnInit {
  public userId;
  // Firebase DB
  public sub: Subscription;
  public itemsRef: AngularFireList<any>;
  public productsItems: Observable<any[]>;
  public productsArray: Array<any> = [];
  public filteredItems: Array<any> = [];
  // Auth data
  public emailData;
  public userName;

  constructor(public routes: ActivatedRoute,
    public db: AngularFireDatabase,
    public authService: AuthenticationService,
    public router: Router
  ) {
    this.itemsRef = db.list('products');
    this.productsItems = this.itemsRef.valueChanges();
  }

  ngOnInit() {
    this.userId = this.routes.snapshot.paramMap.get(`id`);
    this.getproduct();
    this.getUserDetails();
    this.authService.userSubject.subscribe(res => {
     // this.router.navigateByUrl('usuarios/acceso/' + res.uid).then(() => window.location.reload());
     this.router.navigateByUrl('usuarios/acceso/' + res.uid);
     this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    });


  }

  public getproduct() {
    this.sub = this.productsItems.subscribe(array => {
      array.forEach(res => {
        res.map(resm => {
          this.productsArray.push(resm);
        });
      });
      this.productsArray.map(res => {

        if (res.userId === this.userId) {
          this.filteredItems.push(res);

        }
      });
    });
  }

  public getUserDetails() {
    this.authService.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          this.emailData = user.email;
          this.userName = user.displayName;

          // do something
        } else {

          // do something else
        }
      })
    ).subscribe();
  }


  public onSignOut() {
    this.authService.SignOut();
    this.emailData = null;
    this.userName = null;
    this.userId = null;
    localStorage.removeItem('token');
    this.router.navigateByUrl('/').then(() => window.location.reload());
  }
}
