import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ɵConsole } from '@angular/core';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AlertService } from 'src/app/services/alertService/alertService';
import { UniquePipe } from 'ngx-pipes';
import { trigger, transition, style, animate } from '@angular/animations';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
declare var $;
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
  providers: [UniquePipe],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class StoreComponent implements OnInit {
  public products: Array<any> = [];
  public categoryArray: any[] = [];
  public brandArray: any[] = [];
  public noProducts: boolean = false;
  public noFilter: boolean = true;
  public loading: boolean = true;
  /*Pagination */
  public page: number = 1;
  public pageSize: number = 12;
  public wordBreak = false;
  public formNouiSlider: FormGroup;
  public dropdownToggle: boolean = true;

  /* Nouislider */
  public filterPriceActive: boolean = true;
  public filteredPrice: Array<any> = [];
  /*Filter by Brand */
  public brandNames: Array<any> = [];
  public filterBrandActive: boolean = false;
  public brandName: string;

  // Firebase data
  public itemsRef: AngularFireList<any>;
  public productsItems: Observable<any[]>;
  public mostSellingProduct: any[] = [];

  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('imgProduct', { static: false }) imgProduct: ElementRef;
  public minValue;
  public maxValue;
  sliderRange;
  someKeyboardConfig: any = {
    connect: true,
    start: [0, 100000],
    step: 10,
    tooltips: true,
    range: {
      min: 0,
      max: 100000
    },
    behaviour: 'drag',
  };

  // Img Spinner
  public loadingImg = true;

  // tslint:disable-next-line:max-line-length
  constructor(public productsService: ProductsService,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public alertService: AlertService,
    public uniquePipe: UniquePipe,
    public location: Location,
    public db: AngularFireDatabase,
    private elRef: ElementRef<HTMLImageElement>
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        const routeName = event.url.split("/productos/").pop();
        //  console.log(routeName);

        this.getCategories(routeName);
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    this.itemsRef = db.list('productRanking');
    this.productsItems = this.itemsRef.valueChanges();
  }


  ngOnInit() {
    const id = this.route.snapshot.paramMap.get(`id`);
    this.getBestSellers();
    this.getCategories(id);

    if ($(window).width() < 960) {
      this.dropdownToggle = false;
    }

  }

  public addToCart(product: any) {
    this.alertService.info('Producto Añadido');
    this.productsService.addToCart(product);
  }


  public getCategories(productCategories) {
    switch (productCategories) {
      case 'todos':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getProducts().subscribe(res => {
          if (res.length == 0) {
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'auriculares':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryAuriculares().subscribe(res => {
          /* Removing products that have Errors of the admin */
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'portatiles':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryPortatiles().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;


            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'ofertas':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryOffers().subscribe((res) => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'camaras':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryCameras().subscribe((res) => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'telefonos':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryTelefonos().subscribe((res) => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'otros-productos':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryOtros().subscribe((res) => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;

            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'filtrado-por-marca':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.brandName = localStorage.getItem('brand');
        // console.log(this.brandName);
        this.productsService.getProducts().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            // this.products = res;
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                if (this.brandName === mapItem.acf.marca) {
                  this.products.push(mapItem);
                  this.brandArray.push(mapItem);
                  this.filterBrandActive = true;
                  this.noFilter = false;
                  this.loading = false;
                  this.brandNames.push(mapItem.acf.marca);
                }
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'almacenamiento':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];

        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryAlmacenamiento().subscribe(res => {

          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'monitores':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryMonitores().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'tablet':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryTablet().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'placas-de-video':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryPlacasDeVideo().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'motherboards':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryMotherboards().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'procesadores':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryProcesadores().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'fuentes':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryFuentes().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'memorias':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryMemorias().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'gabinetes':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryGabinetes().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;

            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      case 'perifericos':
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getCategoryPerifericos().subscribe(res => {

          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
      default:
        this.products = [];
        this.categoryArray = [];
        this.loadingImg = true;
        this.brandNames = [];
        this.filterBrandActive = false;
        this.noFilter = true;
        this.loading = true;
        this.productsService.getProducts().subscribe(res => {
          if (res.length == 0) {
            console.log('here');
            this.noProducts = true;
            this.loading = false;
            return;
          } else {
            this.noProducts = false;
            /* Removing products that have Errors of the admin */
            res.map((mapItem) => {
              // console.log(mapItem.better_featured_image);
              if (mapItem.better_featured_image !== null
                && mapItem.better_featured_image !== undefined
                && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
              ) {
                /* Filling the Arrays */
                this.products.push(mapItem);
                this.categoryArray.push(mapItem);
                /*Filling the Brand Names */
                this.brandNames.push(mapItem.acf.marca);
              }
            });
            this.loading = false;
          }
        });
        break;
    }

  }

  public filterByBrand(brand) {
    // this.filterBrand = [];
    this.loading = true;
    this.brandName = brand;
    localStorage.setItem('brand', brand);
    this.router.navigate(['productos', 'filtrado-por-marca']);
    // Generate the URL:
    //  let url = this.router.createUrlTree(['productos/', 'filterbybrand']).toString();
    //  this.location.go(url);

  }

  public getBestSellers() {
    let tempArray = [];
    this.loading = true;
    this.productsService.getProducts().subscribe((res) => {
      this.productsItems.subscribe((itemArray) => {
        itemArray.map((mfireRes) => {
          res.map((resMap) => {
            if (resMap.id === mfireRes.productId) {
              tempArray.push(resMap);
            }
          });
          this.mostSellingProduct = [...new Set(tempArray)];
          // console.log(this.mostSellingProduct);
          this.loading = false;
        });
      });

    });
  }

  /* Nouislider Slider */
  onChange(event) {
    //  this.loadingImg = true;
    let tempArray = [];
    this.minValue = event[0];
    this.maxValue = event[1];
    if (this.filterBrandActive) {
      //  this.loadingImg = true;
      //  this.loading = true;
      this.brandArray.map((mapItem) => {
        //   this.loading = false;
        this.filterPriceActive = false;
        if (mapItem.acf.precio_actual >= this.minValue && mapItem.acf.precio_actual <= this.maxValue) {
          tempArray.push(mapItem);
          //  console.log(tempArray);
          /* Reassigning the filter products */
          this.products = [...new Set(tempArray)];
        }
      });
    } else {
      this.categoryArray.map(mapItem => {
        if (mapItem.acf.precio_actual >= this.minValue && mapItem.acf.precio_actual <= this.maxValue) {
          tempArray.push(mapItem);
          /* Reassigning the filter products */
          this.products = [...new Set(tempArray)];
        }
      });
    }
  }

  public loadPage($event) {
    //   console.log($event);
    this.wordBreak = true;
    this.loadingImg = true;
  }



  sumMax(value) {
    this.maxValue = value + 100;
  }
  sumMin(value) {
    this.minValue = value + 100;
  }
  subMax(value) {
    this.maxValue = value - 100;
  }
  subMin(value) {
    this.minValue = value - 100;
  }

}
