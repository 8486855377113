import { Component, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import '../assets/Lib/Floating-WhatsApp-Message-Button-jQuery/floating-wpp.min.js';
declare var $;
declare var floatingWhatsApp;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'ecommerceElectro';
  public ngOnInit() {
    this.WhatsAppButton();
  }

  onClickInstagramBTN() {
   
    var url = `https://www.instagram.com/aft.online/`;
    window.open(url, "_blank");  
  } 

  public WhatsAppButton() {

    $(function () {
      $('#WAButton').floatingWhatsApp({
        phone: '+5493854768038', //WhatsApp Business phone number International format-
        //Get it with Toky at https://toky.co/en/features/whatsapp.
        headerTitle: 'Amadís Farina Tecnologías', //Popup Title
        popupMessage: 'Gracias por comunicarte con AFT! En que podemos ayudarle?', //Popup Message
        showPopup: true, //Enables popup display
        buttonImage: '<img style="z-index: 1 !important;" src="https://rawcdn.githack.com/rafaelbotazini/floating-whatsapp/3d18b26d5c7d430a1ab0b664f8ca6b69014aed68/whatsapp.svg" />', //Button Image
        //headerColor: 'crimson', //Custom header color
        //backgroundColor: 'crimson', //Custom background button color
        position: "right"
      });
    });
  }


}
