import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupName } from '@angular/forms';
import * as moment from 'moment';
import { WhatsAppSMSService } from 'src/app/services/SMS-Whats-App-Service/SMS-whatapp-messages.service';
import { EmailServiceService } from 'src/app/services/Email-Service/email.service';
import { AlertService } from 'src/app/services/alertService/alertService';
import Swal from "sweetalert2";
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticationService } from 'src/app/services/AuthService/authentication.service';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { trigger, transition, style, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CheckoutComponent implements OnInit {
  public loading: boolean = true;
  public products: any[] = [];
  public shippingForm: FormGroup;
  public authenticationForm: FormGroup;
  public listOfDeliveryProducts: any = [];
  public domAuthCheckBox: any;
  public user = {
    email: '',
  };
  // Firebase Auth
  public key;
  public userId;
  public loggedUser;

  // Firebase Db
  public itemsRef: AngularFireList<any>;
  public storedProducts: object;
  public storedProductsArray: Array<any>;
  public productsItems: Observable<any[]>;

  constructor(
    public productsService: ProductsService,
    public formBuilder: FormBuilder,
    public whatsSMSService: WhatsAppSMSService,
    public emailService: EmailServiceService,
    public alertService: AlertService,
    public router: Router,
    public angularFireAuth: AngularFireAuth,
    public authService: AuthenticationService,
    public db: AngularFireDatabase,
  ) {
    this.itemsRef = db.list('products');
    this.productsItems = this.itemsRef.valueChanges();
  }

  ngOnInit() {
    this.loading = false;
    this.productsService.Data.cartList.forEach(items => {
      this.products.push(items.title.rendered + ' ');
    });
    /* Initializing Forms */
    this.shippingForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      address: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      zip: ['', Validators.required],
      phone: ['', Validators.required],
      orderNotes: [''],
      products: [''],
      date: [''],
      mercadoPagoInfo: [''],
      userId: [''],
      totalPrice: ['']
    });

    this.authenticationForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });

    const deliveryProducts = this.productsService.Data.cartList.map((item) => {
      return {
        title: 'Producto: ' + item.title.rendered,
        price: 'Costo: ' + '$' + item.acf.precio_actual,
      };
    });
    deliveryProducts.forEach(item => {
      this.listOfDeliveryProducts.push(Object.values(item).toString());
    });
    this.shippingForm.controls.products.setValue(this.listOfDeliveryProducts.toString());
    this.getUserDetails();
  }

  public sendUserToWhatsApp() {
    if (!this.productsService.Data.totalPrice) {
      return;
    }
    if (!this.loggedUser) {
      this.checkoutNotLoggedUser();
    } else {
      this.checkOutLoggedUser();
    }

  }


  public sendDeliveryEmail() {
    /* Email Service */
    this.shippingForm.controls.date.setValue(moment().format('Do MMM YYYY'));
    this.shippingForm.controls.totalPrice.setValue(this.productsService.Data.totalPrice);
    // tslint:disable-next-line:max-line-length
    this.shippingForm.controls.mercadoPagoInfo.setValue('Visite Mercado Pago para ver la lista de Productos https://www.mercadopago.com.ar/activities#from-section=menu');
    // this.shippingForm.controls.userId.setValue(this.userId);
    this.emailService.sendDeliveryEmail(this.shippingForm.value).subscribe(res => {
    });
    this.emailService.sendDeliveryEmailToMe(this.shippingForm.value).subscribe(res => {
    });
  }

  public SMSMessage() {
    // console.log('triggered');
    let messageObj = {
      message: '',
    };
    let tempArray = [];

    /* Twilio Messages */
    messageObj.message =
      '*El Cliente:* ' + this.shippingForm.controls.firstName.value +
      ' ' + this.shippingForm.controls.lastName.value + '.' +
      ' *Correo:* ' + this.shippingForm.controls.email.value + '.' +
      ' *Dirección:* ' + this.shippingForm.controls.address.value + '.' +
      ' *Ciudad:* ' + this.shippingForm.controls.city.value + '.' +
      ' *Pais:* ' + this.shippingForm.controls.country.value + '.' +
      ' *Código Postal:* ' + this.shippingForm.controls.zip.value + '.' +
      ' *Teléfono:* ' + this.shippingForm.controls.phone.value + '.' +
      ' *Notas:* ' + this.shippingForm.controls.orderNotes.value + '.' +
      ' *Costo Total:* $' + this.productsService.Data.totalPrice + '.' +
      ' *Fecha:* ' + moment().format('Do MMM YYYY') + '.' +
      // tslint:disable-next-line:max-line-length
      ' *Productos:* ' + 'Visite Mercado Pago para ver la lista de Productos https://www.mercadopago.com.ar/activities#from-section=menu' + '.';
    this.whatsSMSService.sendSMS(messageObj).subscribe();
  }

  public swalCheckOutConfirm() {
    Swal.fire(
      'Información de pedido Enviada 🚚.',
      'Haga click para Pedir el Link De Mercado Pago.',
      'success',
    ).then(resp => {
      //  this.alertService.success('Se ha registrado la compra verifique su correo');
      var decoded = decodeURIComponent('Hola Buen día, Quiero un Link de Mercado Pago de estos Productos : ' + this.products.toString());
      setTimeout(() => {
        console.log('sended to whatsapp');
        window.location.href = 'https://api.whatsapp.com/send?phone=543854768038&text=' + decoded;
      }, 1000);
      this.shippingForm.reset();
      this.productsService.Data.cartList = [];
      this.productsService.Data.totalPrice = null;
    });
  }

  public checkoutNotLoggedUser() {
    // creating the obj model for the db
    // tslint:disable-next-line:max-line-length
    if (this.domAuthCheckBox) {
      // tslint:disable-next-line:max-line-length
      this.angularFireAuth.createUserWithEmailAndPassword(this.authenticationForm.controls.email.value, this.authenticationForm.controls.password.value).then((res) => {
        this.userId = res.user.uid;
        this.user.email = res.user.email;
        this.authService.SendSubjectUserData(res.user);
        localStorage.setItem('token', 'true');

        // creating the obj model for the db
        this.storedProducts = this.productsService.Data.cartList.map((item) => {
          return {
            userId: res.user.uid,
            prodId: item.id,
            title: item.title.rendered,
            price: item.acf.precio_actual,
            date: moment().format('Do MMM YYYY'),
          };
        });
        /** EMAIL Register SERVICE */
        this.registerEmail(this.user);
        /*Email Delivery Service */
        this.sendDeliveryEmail();
        this.SMSMessage();
        /* Firebase Db */
        this.itemsRef.push(this.storedProducts).then((res) => {
          this.swalCheckOutConfirm();
        }, error => console.error(error));
      });
    } else {
      /*Email Delivery Service */
      this.sendDeliveryEmail();
      this.SMSMessage();
      this.swalCheckOutConfirm();
    }

  }



  public checkOutLoggedUser() {
    /*Email Delivery Service */
    this.sendDeliveryEmail();
    this.SMSMessage();
    // creating the obj model for the db
    this.storedProducts = this.productsService.Data.cartList.map((item) => {
      return {
        userId: this.loggedUser.uid,
        prodId: item.id,
        title: item.title.rendered,
        price: item.acf.precio_actual,
        date: moment().format('Do MMM YYYY'),
      };
    });
    /* Firebase Db */
    this.itemsRef.push(this.storedProducts).then((res) => {
      this.swalCheckOutConfirm();
    }, error => console.error(error));
    // console.log(res);
  }

  public getUserDetails() {
    this.loading = true;
    this.authService.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          this.loggedUser = user;
          this.loading = false;
          this.userId = user.uid;
          localStorage.setItem('token', 'true');
          // do something
        } else {
          this.loggedUser = false;
          this.loading = false;

          // do something else
        }
      })
    ).subscribe();
  }

  public registerEmail(useremail) {
    this.emailService.sendNewRegisteredEmail(useremail).subscribe((res: any) => {
    },
      error => {
        console.log(error);
      });
    this.emailService.sendRegisterEmailToMe(useremail).subscribe((res: any) => {
    }, error => {
      console.log(error);
    });
  }



}
