import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public slidesArray: Array<any> = [];
  public slideConfigSlider = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": true,
    "arrows": false,
    "autoplaySpeed": 1000
  };
  constructor() { }

  ngOnInit() {
    this.slidesArray = [
      { src: './../../../../assets/img/About_Us_Slider_1.min.jpg' },
      { src: './../../../../assets/img/About_Us_Slider_2.min.jpg' },
    ];
  }

}
