import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FrequentQuestionComponent } from './frequent-question/frequent-question.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';


const routes: Routes = [
    { path: 'la-empresa', component: AboutComponent },
    { path: 'contacto', component: ContactComponent },
    { path: 'terminos-y-condiciones', component: TermsConditionsComponent },
    { path: 'preguntas-frecuentes', component: FrequentQuestionComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule { }
