import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUserComponent } from './create-user/create-user.component';
import { UsersPanelComponent } from './users-panel/users-panel.component';
import { AuthGuardService } from 'src/app/helpers/auth-guard.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';



const routes: Routes = [
  { path: 'usuarios', component: CreateUserComponent },
  { path: 'acceso/:id', component: UsersPanelComponent, canActivate: [AuthGuardService] },
  { path: 'restablecer-contraseña', component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
