import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { trigger, transition, animate, style } from '@angular/animations';
import { AlertService } from 'src/app/services/alertService/alertService';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetEmailForm: FormGroup;
  constructor(private fb: FormBuilder, public fireAuth: AngularFireAuth, public alertService: AlertService) { }

  ngOnInit() {
    this.resetEmailForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }



  public onSubmitChangeEmail() {
    // const email = this.resetEmailForm.value;
    // Send a password reset email
    this.fireAuth.sendPasswordResetEmail(this.resetEmailForm.controls.email.value).then(() => {
      // Email sent.
      this.alertService.success('Verifique su correo para restablecer la contraseña');

    }).catch(function (error) {
      console.error(error);
    });

  }

}
