import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateUserComponent } from './create-user/create-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './auth-routing.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { UsersPanelComponent } from './users-panel/users-panel.component';
import { CoreModule } from '../core/core.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from 'src/app/elements/shared.module';



@NgModule({
  declarations: [
    CreateUserComponent,
    UsersPanelComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthenticationRoutingModule,
    AngularFireAuthModule,
    SharedModule
  ],
  exports: [
    CreateUserComponent,
    UsersPanelComponent,
    ResetPasswordComponent
  ],
})
export class AuthModuleModule { }
