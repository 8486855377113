import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../alertService/alertService';
import { first } from 'rxjs/operators';
import { EmailServiceService } from '../Email-Service/email.service';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    userData: Observable<any>;
    provider = new firebase.auth.GoogleAuthProvider();
    userObservable: Observable<any>;
    userSubject = new Subject<any>();
    currentUserSubject: BehaviorSubject<any>;
    authErrorObserver: Observable<any>;
    authErrorSubject = new Subject<any>();
    loadingSubject: BehaviorSubject<boolean>;
    user = {
        email: '',
    };
    // behaviorSub Login
    public userLoggedCheck: BehaviorSubject<any>;
    // tslint:disable-next-line:max-line-length
    constructor(public http: HttpClient,
        public angularFireAuth: AngularFireAuth,
        public router: Router,
        public alertService: AlertService,
        public emailService: EmailServiceService
    ) {
        this.userData = angularFireAuth.authState;
        this.userObservable = this.userSubject.asObservable();
        this.currentUserSubject = new BehaviorSubject('');
        this.loadingSubject = new BehaviorSubject(false);
        this.userLoggedCheck = new BehaviorSubject(false);
        this.authErrorObserver = this.authErrorSubject.asObservable();
    }




    SendSubjectUserData(data) {
        //  console.log(data); I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.userSubject.next(data);
    }
    SendError(data) {
        //  console.log(data); I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.authErrorSubject.next(data);
    }


    /* Sign up */
    SignUp(email: string, password: string) {
        this.angularFireAuth.createUserWithEmailAndPassword(email, password)
            .then(res => {
                //  console.log(this.user);
                this.user.email = res.user.email;
                /** EMAIL SERVICE */

                this.emailService.sendNewRegisteredEmail(this.user).subscribe((res: any) => {
                    this.alertService.success('Se ha Registrado a DuodecimStudio Ecommerce');
                },
                    error => {
                        //    console.log(error);
                    });
                this.emailService.sendRegisterEmailToMe(this.user).subscribe((res: any) => { }, error => {
                    //    console.log(error);
                });
                this.SendSubjectUserData(res.user);
                localStorage.setItem('token', 'true');
                //   console.log('Successfully signed up!', res);
                this.router.navigate(['usuarios/acceso/', res.user.uid]);
            })
            .catch(error => {
                //    console.log('Something is wrong:', error.message);
                this.SendError(error.message);
            });
    }

    /* Sign in */
    SignIn(email: string, password: string) {
        // this.loadingSubject.next(true);
        this.angularFireAuth
            .signInWithEmailAndPassword(email, password)
            .then(res => {
                //    console.log(res.user);
                if (res.user) {
                    this.loadingSubject.next(false);
                    this.SendSubjectUserData(res.user);
                    localStorage.setItem('token', 'true');
                    this.router.navigate(['usuarios/acceso/', res.user.uid]);
                }
            })
            .catch(err => {
                // console.log('Something is wrong:', err.message);
                this.SendError(err.message);
            });
    }

    /* Sign out */
    SignOut() {
        this.angularFireAuth.signOut();
    }
    // is logged in
    isLoggedIn() {
        return this.angularFireAuth.authState.pipe(first());
    }

    /* Authentification Access */
    public hasToken(): boolean {
        return !!localStorage.getItem('token');
    }



}
