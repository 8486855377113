import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
    providedIn: 'root'
})
export class WhatsAppSMSService {
    private local = 'http://localhost:3000/sendSMS';
    private enpointSMS = environment.emailSmSEnpoint;
    private smsEnpoint = '/sendSMS';

    constructor(private http: HttpClient) { }

    public sendSMS(body: any): Observable<any> {
        return this.http.post(`${this.enpointSMS}${this.smsEnpoint}`, body, { observe: 'response' });
    }

}
