import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/AuthService/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    public auth: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.auth.hasToken();

    if (currentUser) {
      // authorised so return true
      // this.userService.currentUserSubject.next(true);
      //  console.log('Guard Called and passed');
      return true;
    }
    // not logged in, so redirect to login page with the return url
    this.router.navigate(['/usuarios'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
