import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthenticationService } from 'src/app/services/AuthService/authentication.service';
import { GApiService } from 'src/app/services/AuthService/gApi-Service/g-api.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public registerForm: FormGroup;
  public authError;
  public loading = false;
  public loadingIcon = false;
  public gApiError: BehaviorSubject<any>;
  constructor(
    private fb: FormBuilder,
    public authService: AuthenticationService,
    public gapi: GApiService,
    public element: ElementRef,
    public router: Router
  ) {
    this.gApiError = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });

    this.getUserDetails();

  }

  signUp() {
    this.loadingIcon = true;
    let email = this.registerForm.controls.email.value;
    let password = this.registerForm.controls.password.value;
    this.authService.SignUp(email, password);
    this.authService.authErrorObserver.subscribe((data) => { this.authError = data; });
    this.registerForm.reset();
  }

  signIn() {
    this.loadingIcon = true;
    let email = this.registerForm.controls.email.value;
    let password = this.registerForm.controls.password.value;
    this.authService.SignIn(email, password);
    this.loading = this.authService.loadingSubject.value;
    // this.authError.next(this.authService.authError.value);
    this.authService.authErrorObserver.subscribe((data) => { this.authError = data; });
    this.registerForm.reset();
  }

  loginWithGoogle() {
    this.loadingIcon = true;
    this.gapi.signIn();
    this.gapi.errorObservable.subscribe(res => {
      this.gApiError.next(res);
    });

  }
  public getUserDetails() {
    this.authService.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          this.router.navigate(['usuarios/acceso/', user.uid]);
          // do something
        } else {

          // do something else
        }
      })
    ).subscribe();
  }





}

