import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailServiceService } from 'src/app/services/Email-Service/email.service';
import { AlertService } from 'src/app/services/alertService/alertService';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public newsletterForm: FormGroup;
  public loadingIcon;
  constructor(public fb: FormBuilder, public emailService: EmailServiceService, public alertService: AlertService) { }

  ngOnInit() {
    this.newsletterForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngSubmitNewsletter() {
    this.loadingIcon = true;
    this.emailService.sendNewsubscription(this.newsletterForm.value).pipe(delay(1000)).subscribe((res: any) => {
      /*Añadir Numero de Nombre */
      this.alertService.success('Se ha Subscrito a DuodecimStudio Ecommerce');
      this.newsletterForm.reset();
      this.loadingIcon = false;
    }, error => {
      /*Añadir Numero de teléfono */
      this.alertService.error('Hay un problema en este momento, intente enviarlo en unos minutos');
      this.newsletterForm.reset();
      this.loadingIcon = false;
      console.log(error);
    });
    this.emailService.sendSubscriptionToMe(this.newsletterForm.value).pipe(delay(1000)).subscribe((res: any) => {
    }, error => {
      this.newsletterForm.reset();
      console.log(error);
    });
  }

}
