export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBtIhv1APqFNsdqxujPjyJUnZFS8LkRVQY',
    authDomain: 'amadisfarinatecnologias.firebaseapp.com',
    databaseURL: 'https://amadisfarinatecnologias.firebaseio.com',
    projectId: 'amadisfarinatecnologias',
    storageBucket: 'amadisfarinatecnologias.appspot.com',
    messagingSenderId: '994234121811',
    appId: '1:994234121811:web:e2962fa819f9a6db5b90a6'
  },
  WpEcommercePostEnpoint: 'https://aftserver.com.ar/wp-json/wp/v2/posts?_embed',
  WpEcommercePagesEnpoint: 'https://www.aftserver.com.ar/wp-json/wp/v2/pages?_embed',
  apiNodeEnpoint: 'https://amadismercadopagoserver.herokuapp.com/api/process_payment',
  emailSmSEnpoint: 'https://amadisemailservice.herokuapp.com',
};
