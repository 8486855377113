import { Component, OnInit, ViewChild, ElementRef, ɵConsole, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { TitleCasePipe } from '@angular/common';
import { style, state, animate, transition, trigger } from '@angular/animations';
import * as $ from 'jquery';
import { AuthenticationService } from 'src/app/services/AuthService/authentication.service';
import { tap, } from 'rxjs/operators';
import { GApiService } from 'src/app/services/AuthService/gApi-Service/g-api.service';
import { BehaviorSubject, } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
  providers: [
    TitleCasePipe
  ],
})
export class HeaderComponent implements OnInit {
  public searchedProduct: Array<any> = [];
  public categories: Array<any> = [];
  public products: any[];
  public loading: boolean = true;
  public menuActive: boolean = false;
  public Toggle
  // Auth data
  public emailData;
  public userName;
  public userId;

  constructor(
    public productsService: ProductsService,
    public router: Router,
    public titlecase: TitleCasePipe,
    public authService: AuthenticationService,
    public elementRef: ElementRef,
    public renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.productsService.getProducts().subscribe((productsResp) => {
      this.searchedProduct = productsResp;
      console.log(this.searchedProduct);
    });
    this.getUserDetails();
    this.authService.userObservable.subscribe((res) => {
      this.getUserDetails();
    });
  }

  public findMatches(wordToMatch, array) {
    return array.filter(filterArray => {
      // here we need to figure out if the city or state matches what was searched
      const regex = new RegExp(wordToMatch, 'gi');
      return filterArray.title.rendered.match(regex);
    });
  }

  displayMatches(value) {
    let temp = [];
    const matchArray = this.findMatches(value, this.searchedProduct);
    matchArray.map(mapArray => {
      const regex = new RegExp(value, 'gi');
      let name = this.titlecase.transform(value);
      const mapArrayNames = mapArray.title.rendered.replace(regex, name);
      console.log(mapArrayNames);
       console.log(mapArray.title.rendered); 

      if (mapArray.title.rendered.toUpperCase() == mapArrayNames.toUpperCase()) {
        temp.push(mapArray);
      }
      this.products = temp;
      console.log(this.products);
    }).join('');

  }


  public toggleNavigation() {
    // this.menuActive = !this.menuActive;
    this.menuActive = true;
    /*
    var sections = document.querySelectorAll('.section');
    console.log(sections);
*/
    $('#responsive-nav').toggleClass('active');
    /*
    function isInPage(node) {
      return (node === document.body) ? false : document.body.contains(node);
    }
    isInPage(document.getElementById('responsive-nav'))
     if (document.getElementById('responsive-nav')) {
       $(window).one("click", () => {
         console.log('window');
     });
    // $('#responsive-nav').removeClass('active');
  }


  // Fix cart dropdown from closing
  $('.cart-dropdown').on('click', function(e) {
    e.stopPropagation();
  });
  */
  }





  public getUserDetails() {
    this.authService.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          this.emailData = user.email;
          this.userName = user.displayName;
          this.userId = user.uid;
          // do something
        } else {

          // do something else
        }
      })
    ).subscribe();
  }

  public navigateToUserPanel() {
    this.router.navigate(['usuarios/acceso/', this.userId]);
  }

  public onSignOut() {
    this.authService.SignOut();
    this.emailData = null;
    this.userName = null;
    this.userId = null;
    localStorage.removeItem('token');
    this.router.navigateByUrl('/').then(() => window.location.reload());
  }


  public navigateMobileMenu(menuNav: string) {
    if ($(window).width() < 960) {
      this.toggleNavigation();
    }
    switch (menuNav) {
      case '/':
        this.router.navigate([menuNav]);
        break;
      case 'ofertas':
        this.router.navigate(['productos', menuNav]);
        break;
      case 'arma-tu-pc':

        this.router.navigate([menuNav]);
        break;
      case 'auriculares':
        this.router.navigate(['productos', menuNav]);
        break;
      case 'portatiles':
        this.router.navigate(['productos', menuNav]);
        break;
      case 'procesadores':
        this.router.navigate(['productos', menuNav]);
        break;
      case 'placas-de-video':
        this.router.navigate(['productos', menuNav]);
        break;
      case 'la-empresa':
        this.router.navigate([menuNav]);
        break;
      case 'contacto':
        this.router.navigate([menuNav]);
        break;
      case 'preguntas-frecuentes':
        this.router.navigate([menuNav]);
        break;
      case 'perifericos':
        this.router.navigate(['productos', menuNav]);
        break;

    }
  }

}
