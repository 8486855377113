import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class AlertService {
    swal: any;
    constructor() {
        this.swal = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            background: '#D10024',
            customClass: {
                title: 'color-text',
                content: 'color-text',
            }
        });
    }

    success(message: string) {
        this.swal.fire({
            type: 'success',
            title: '',
            text: message
        });
    }

    error(message: string) {
        this.swal.fire({
            type: 'error',
            title: '',
            text: message
        });
    }

    info(message: string) {
        this.swal.fire({
            type: 'info',
            title: '',
            text: message
        });
    }

    delete() {
        return Swal.fire({
            title: '¿Está seguro de eliminar el registro?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si,seguro!'
        });
    }
    deleteSuccess() {
        Swal.fire(
            'Eliminado!',
            'Operación realizada correctamente',
            'success'
        );
    }

}
