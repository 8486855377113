import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, } from '@angular/core';
import { ActivatedRoute, Routes, Router, NavigationStart, NavigationEnd, RoutesRecognized, RouterEvent } from '@angular/router';
import { LazyLoadScriptService } from 'src/app/services/Lazy-Load-script.service/lazy-load-script.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
import { AlertService } from 'src/app/services/alertService/alertService';
import { first, take, tap, filter } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';
import { EmailServiceService } from 'src/app/services/Email-Service/email.service';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/AuthService/authentication.service';
import Swal from "sweetalert2";
declare var $;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterViewInit {
  public ratingForm: FormGroup;
  public productId;
  public product: any;
  public products = [];
  public loading = true;
  public submitedRanking = false;
  public commentsRatingsArray: any[] = [];
  public ratingColor;
  public productIdUrl;
  public whatsappNameUrl;
  /*Category Name */
  public categoryName;
  /* Pagination */
  public page: number = 1;
  public pageSize: number = 3;
  /* Ranking */
  @ViewChild("star1", { static: false }) star1: ElementRef;
  @ViewChild("star2", { static: false }) star2: ElementRef;
  @ViewChild("star3", { static: false }) star3: ElementRef;
  @ViewChild("star4", { static: false }) star4: ElementRef;
  @ViewChild("star5", { static: false }) star5: ElementRef;
  // tslint:disable-next-line:max-line-length
  // tslint:disable:align

  //  Scrolling
  @ViewChild('descripcionCommentScroll', { static: false }) public descripcionCommentScroll: ElementRef<any>;

  // Img Slider
  public imageSelected;
  public mobileImages = false;
  // Firebase DB
  public itemsRef: AngularFireList<any>;
  public rankingProductsItems: Observable<any[]>;
  public storedProducts: object;
  public storedrankingProductsArray: Array<any> = [];
  public filteredItems: Array<any> = [];
  // Firebase Auth
  public key;
  public userId;
  public loggedUser;

  //Slick 
  public productSlideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 2,
    "autoplay": true,
    "autoplaySpeed": 2000
  };
  public mobileText: boolean = false;

  // Stars Array
  public oneStar: Array<any> = [];
  public twoStar: Array<any> = [];
  public threeStar: Array<any> = [];
  public fourStar: Array<any> = [];
  public fiveStar: Array<any> = [];
  // Img Spinner
  public loadingImg = true;
  public loadingDescriptionImg = false;

  constructor(private fb: FormBuilder,
    public routes: ActivatedRoute,
    public productsService: ProductsService,
    public router: Router,
    public alertService: AlertService,
    public emailService: EmailServiceService,
    public db: AngularFireDatabase,
    public authService: AuthenticationService,
  ) {
    // Firebase db
    this.itemsRef = db.list('productRanking');
    this.rankingProductsItems = this.itemsRef.valueChanges();
    this.routes.params.subscribe(val => {
     // this.getProduct(val.id);
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
  }

  ngOnInit() {
    this.productId = this.routes.snapshot.paramMap.get(`id`);
    this.ratingForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      comment: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      date: [''],
      stars: [''],
      product: [''],
      productId: ['']
    });
    this.getProduct(this.productId);
    if ($(window).width() < 960) {
      this.mobileImages = true;
      this.mobileText = true;
      this.productSlideConfig = {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000
      };
    } else {
    }
    this.getUserDetails();
    this.storedrankingProductsArray = [];
    this.getRating();
  }

  ngAfterViewInit() {
    this.imgSelected(0);
  }


  public scrollToDescripcionComments() {
    this.descripcionCommentScroll.nativeElement.scrollIntoView();
  }


  public getProduct(id: any) {
    let category;
    let temArray = [];
    let tempObj;
    this.productsService.getProductById(id).subscribe(res => {
      if (res[0].acf.imagen1 == false) {
        res[0].acf.imagen1 = undefined;
      }
      if (res[0].acf.imagen2 == false) {
        res[0].acf.imagen2 = undefined;
      }
      if (res[0].acf.imagen3 == false) {
        res[0].acf.imagen3 = undefined;
      }
      res[0]._embedded['wp:term'][0].forEach(element => {
        if (element.name !== 'Ofertas' && element.name !== 'Uncategorized') {
          temArray.push(element);
        }
      });
      tempObj = temArray.pop();
      if (tempObj) {
        category = tempObj.name.toLowerCase().normalize("NFD")
          .replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '');
        this.categoryName = category;
        this.product = res[0];
        this.whatsappNameUrl = decodeURIComponent(res[0].title.rendered + ' Sitio Web: ' + location.href);

        this.getRelatedProducts(this.categoryName);
        setTimeout(() => {

          this.loading = false;
        }, 800);
      } else {
        this.router.navigate(['/productos', 'todos']);
      }
    });
  }


  public getRelatedProducts(productCategories) {
    switch (productCategories) {
      case 'todos':
        var tempArray = [];
        this.loading = true;
        this.productsService.getProducts().subscribe(res => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
      case 'auriculares':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryAuriculares().subscribe(res => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'portatiles':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryPortatiles().subscribe(res => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'ofertas':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryOffers().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'camaras':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryCameras().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'telefonos':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryTelefonos().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'almacenamiento':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryAlmacenamiento().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
      case 'monitores':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryMonitores().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
      case 'tablet':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryTablet().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
      case 'placas-de-video':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryPlacasDeVideo().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
      case 'motherboards':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryMotherboards().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'procesadores':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryProcesadores().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'fuentes':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryFuentes().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'memorias':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryMemorias().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'gabinetes':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryGabinetes().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'perifericos':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryPerifericos().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }

            }
          });
          this.loading = false;
        });
        break;
      case 'otros-productos':
        this.loading = true;
        var tempArray = [];
        this.productsService.getCategoryOtros().subscribe((res) => {
          /* Removing products that have Errors of the admin */
          res.map((mapItem, index) => {
            // console.log(mapItem.better_featured_image);
            if (mapItem.better_featured_image !== null
              && mapItem.better_featured_image !== undefined
              && mapItem._embedded['wp:term'][0][0].name !== 'Uncategorized'
            ) {
              if (this.product.title.rendered !== mapItem.title.rendered) {
                if (index <= 6) {
                  tempArray.push(mapItem);
                }
                this.products = [... new Set(tempArray)];
              }
            }
          });
          this.loading = false;
        });
        break;
    }
  }

  public addToCart(product: any) {
    this.alertService.info('Producto Añadido');
    this.productsService.addToCart(product);
  }

  /*Rating Methods from Firebase DB */
  public getRating() {
    this.rankingProductsItems.subscribe(array => {
      this.storedrankingProductsArray = [];
      this.filteredItems = [];
      this.oneStar = [];
      this.twoStar = [];
      this.threeStar = [];
      this.fourStar = [];
      this.fiveStar = [];

      array.forEach(res => {
        this.storedrankingProductsArray.push(res);
      });
      this.storedrankingProductsArray.map(res => {

        if (res.productId == this.productId) {

          this.filteredItems.push(res);
          switch (res.stars) {
            case '1': this.oneStar.push(res.stars);
              break;
            case '2': this.twoStar.push(res.stars);
              break;
            case '3': this.threeStar.push(res.stars);
              break;
            case '4': this.fourStar.push(res.stars);
              break;
            case '5': this.fiveStar.push(res.stars);
              break;
          }
        }
      });
    });

  }

  public submitProductRating() {
    this.loadingDescriptionImg = true;
    let date = moment().format('Do MMM YYYY');
    this.ratingForm.controls.date.setValue(date);
    this.ratingForm.controls.product.setValue(this.product.title.rendered);
    this.ratingForm.controls.productId.setValue(this.product.id);
    // this.commentsRatingsArray.push(this.ratingForm.value);
    this.storedrankingProductsArray = [];
    this.filteredItems = [];
    this.getRating();
    // this.submitedRanking = true;
    /* Firebase Db */
    this.itemsRef.push(this.ratingForm.value).then((res) => {
      this.loadingDescriptionImg = false;
      this.swalCheckOutConfirm();
    }, error => {
      console.error(error);
    }
    );
  }

  public ratingValue(value) {
    switch (value) {
      case '1':
        this.ratingForm.controls.stars.setValue(value);
        break;
      case '2':
        this.ratingForm.controls.stars.setValue(value);
        break;
      case '3':
        this.ratingForm.controls.stars.setValue(value);
        break;
      case '4':
        this.ratingForm.controls.stars.setValue(value);
        break;
      case '5':
        this.ratingForm.controls.stars.setValue(value);
        break;
    }
  }

  public changeRoute(res) {
    const routeName = res.url.split("/producto/").pop();
    this.router.navigate(['/producto/', routeName]);
  }

  public imgSelected(value) {
    if (value == 0) {
      this.loadingImg = false;
      this.imageSelected = value;
      return;
    }
    this.loadingImg = true;
    this.imageSelected = value;
  }

  public getUserDetails() {
    // this.loading = true;
    this.authService.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          this.loggedUser = user;
          //  this.loading = false;
          this.userId = user.uid;
          localStorage.setItem('token', 'true');
          // do something
        } else {
          this.loggedUser = false;
          //  this.loading = false;

          // do something else
        }
      })
    ).subscribe();
  }


  public swalCheckOutConfirm() {
    Swal.fire(
      'Comentario Añadido !',
      'El Comentario se añadio correctamente',
      'success',
    ).then(resp => {
      /* Email Service */
      this.emailService.sendRankingEmailToMe(this.ratingForm.value).subscribe(res => {
      });
      this.ratingForm.reset();
    });
  }
}
