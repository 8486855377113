import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/Ecommerce-Api/products.service';
declare var $;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
  public dropdownToggle = false;
  public productNoPrice = false;
  // total = [];
  // grandTotal = 0;

  constructor(public productsService: ProductsService) { }

  ngOnInit() {

  }

  public remove(product) {
    const index: number = this.productsService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.productsService.Data.cartList.splice(index, 1);
      this.productsService.Data.totalPrice = this.productsService.Data.totalPrice - parseInt(product.acf.precio_actual, 10);
    }
  }

  public dropdownOpen() {
    this.dropdownToggle = !this.dropdownToggle;
  }


}
