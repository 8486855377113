import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment.prod';
import { UniquePipe } from 'ngx-pipes';
import { CoreModule } from './components/core/core.module';
import { AngularFireAuth } from '@angular/fire/auth';
import { EcommerceModule } from './components/ecommerce/ecommerce.module';
import { AuthModuleModule } from './components/authentication/auth-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommingSoonTemplateComponent } from './components/comming-soon-template/comming-soon-template.component';
@NgModule({
  declarations: [
    AppComponent,
    CommingSoonTemplateComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    EcommerceModule,
    AuthModuleModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [UniquePipe, AngularFireAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }
