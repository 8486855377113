import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbPaginationModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { NgPipesModule } from 'ngx-pipes';
import { HomeComponent } from './home/home.component';
import { StoreComponent } from './store/store.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProductComponent } from './store/product/product.component';
import { EcommerceRoutingModule } from './ecommerce-routing.module';
import { CoreModule } from '../core/core.module';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoadedDirective } from 'src/app/elements/loading-img-element/loading-img-directive';
import { ArmaTuPCComponent } from './arma-tu-pc/arma-tu-pc.component';
import { SharedModule } from 'src/app/elements/shared.module';
import { SafeStylePipe } from 'src/app/pipe/safeStyles.pipe';
import { NoProductsCategoryComponent } from './store/no-products-category/no-products-category.component';
@NgModule({
  declarations: [
    HomeComponent,
    StoreComponent,
    CheckoutComponent,
    ProductComponent,
    LoadedDirective,
    ArmaTuPCComponent,
    SafeStylePipe,
    NoProductsCategoryComponent,

  ],
  imports: [
    CommonModule,
    EcommerceRoutingModule,
    SharedModule,
    SlickCarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbPaginationModule,
    NouisliderModule,
    NgPipesModule,
    AngularFireDatabaseModule,
  ],
  exports: [
    HomeComponent,
    StoreComponent,
    CheckoutComponent,
    ProductComponent,
  ]
})
export class EcommerceModule { }
