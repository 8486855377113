import { HomeComponent } from '../ecommerce/home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CartComponent } from './cart/cart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingElementComponent } from 'src/app/elements/loading-element/loading-element.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AboutComponent } from './about/about.component';
import { CoreRoutingModule } from './core.routing.module';
import { ContactComponent } from './contact/contact.component';
import { FrequentQuestionComponent } from './frequent-question/frequent-question.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SharedModule } from 'src/app/elements/shared.module';



@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        CartComponent,
        AboutComponent,
        ContactComponent,
        FrequentQuestionComponent,
        TermsConditionsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreRoutingModule,
        SlickCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        CartComponent,
    ],
    providers: [],
    bootstrap: []
})
export class CoreModule { }
