import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
    providedIn: 'root'
})
export class EmailServiceService {
    private emailEnpoint = environment.emailSmSEnpoint;
    private local = 'http://localhost:3000';
    private endpointNewsubscription = '/newsubscription';
    private enpointSubscriptionToMe = '/subscriptionToMe';
    private endpointRegisterEmailToMe = '/registerEmailToMe';
    private endpointnewRegisterEmail = '/newRegisterEmail';
    private endpointDeliveryEmail = '/deliveryEmail';
    private endpointDeliveryEmailToMe = '/deliveryEmailToMe';
    private endpointRankingEmailToMe = '/rankingEmailToMe';
    private endpointContactEmailToMe = '/contactToMe';
    constructor(private http: HttpClient) { }

    public sendNewsubscription(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointNewsubscription}`, body, { observe: 'response' });
    }

    public sendSubscriptionToMe(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.enpointSubscriptionToMe}`, body, { observe: 'response' });
    }

    public sendRegisterEmailToMe(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointRegisterEmailToMe}`, body, { observe: 'response' });
    }

    public sendNewRegisteredEmail(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointnewRegisterEmail}`, body, { observe: 'response' });
    }
    public sendDeliveryEmail(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointDeliveryEmail}`, body, { observe: 'response' });
    }
    public sendDeliveryEmailToMe(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointDeliveryEmailToMe}`, body, { observe: 'response' });
    }
    public sendRankingEmailToMe(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointRankingEmailToMe}`, body, { observe: 'response' });
    }

    public sendContactEmailToMe(body: any): Observable<any> {
        return this.http.post(`${this.emailEnpoint}${this.endpointContactEmailToMe}`, body, { observe: 'response' });
    }

    /*
        public sendNewRegisteredEmail(body: any): Observable<any> {
            return this.http.post(`${this.api}${this.endpointnewRegisterEmail}`, body, { observe: 'response' });
        }
        public sendNewRegisteredEmail(body: any): Observable<any> {
            return this.http.post(`${this.api}${this.endpointnewRegisterEmail}`, body, { observe: 'response' });
        }
    */
}
