import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { EmailServiceService } from '../../Email-Service/email.service';
import { AlertService } from '../../alertService/alertService';
// import { EmailServiceService } from '../emailService/email-service.service';
declare var gapi: any;
@Injectable({
    providedIn: 'root'
})
export class GApiService {
    private authInstance: gapi.auth2.GoogleAuth;
    private subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public aspToken: string;
    public picture: string;
    public isLoggedIn: boolean = false;
    public isAuth2Loaded: boolean = false;
    public userId;
    public userObjEmail = {
        email: '',
    };

    // async data
    public userObservable: Observable<any>;
    public userSubject = new Subject<any>();
    public errorObservable: Observable<any>;
    public errorSubject = new Subject<any>();
    // tslint:disable-next-line:max-line-length
    // public authenticationService: AuthenticationService,  public emailService: EmailServiceService
    constructor(
        public router: Router,
        public authenticationService: AuthenticationService,
        public emailService: EmailServiceService,
        public alertService: AlertService,
    ) {
        gapi.load('auth2', function () { });
        this.userObservable = this.userSubject.asObservable();
        this.errorObservable = this.errorSubject.asObservable();
    }

    public getIsLoggedIn(): Observable<boolean> {
        return this.subject.asObservable();
    }



    SendSubjectUserData(data) {
        //  console.log(data); I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.userSubject.next(data);
    }
    SendSubjectErrorApiData(data) {
        //  console.log(data); I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.errorSubject.next(data);
    }

    public signIn(): void {
        if (this.isLoggedIn) {
            return;
        }
        gapi.auth2.init({
            client_id: '994234121811-o7els43u1f7c2un4d62gh1db2c4lkcg8.apps.googleusercontent.com',
            fetch_basic_profile: true
        }).then((authInstance) => {
            this.authInstance = authInstance;
            authInstance.signIn().then((authUser) => {
                console.log(authUser);
                if (authUser.Zb == null) {
                    console.log('here');
                    this.SendSubjectErrorApiData(true);
                    return;
                }
                const credential = firebase.auth.GoogleAuthProvider.credential(
                    authUser.Zb.id_token,
                    authUser.Zb.access_token);
                this.authenticationService.angularFireAuth.signInWithCredential(credential).then(res => {
                    if (res.user) {
                        if (res.user.metadata.creationTime === res.user.metadata.lastSignInTime) {
                            console.log('register user');
                            this.userId = res.user.uid;
                            this.authenticationService.SendSubjectUserData(res.user);
                            localStorage.setItem('token', 'true');
                            this.SendSubjectUserData(res.user);
                            this.userObjEmail.email = res.user.email;
                            console.log('register');
                            /* EMAIL SERVICE  */

                            this.emailService.sendNewRegisteredEmail(this.userObjEmail).subscribe((res: any) => {
                                this.alertService.success('Se ha Registrado a AftOnline Ecommerce');
                                setTimeout(() => {
                                    // tslint:disable-next-line:max-line-length
                                    this.router.navigateByUrl('usuarios/acceso/' + this.userId).then(() => window.location.reload());
                                }, 100);
                            }, error => {
                                console.log(error);
                            });
                            this.emailService.sendRegisterEmailToMe(this.userObjEmail).subscribe((res: any) => { }, error => {
                                console.log(error);
                            });

                        } else {
                            console.log('logged user');
                            this.userId = res.user.uid;
                            this.authenticationService.SendSubjectUserData(res.user);
                            localStorage.setItem('token', 'true');
                            this.SendSubjectUserData(res.user);

                            setTimeout(() => {
                                // tslint:disable-next-line:max-line-length
                                this.router.navigateByUrl('usuarios/acceso/' + this.userId).then(() => window.location.reload());
                            }, 100);

                        }
                    }
                });

            });
        });
    }

    public signOut(): void {
        if (this.authInstance) {
            this.authInstance.signOut().then(() => {

            }, (err) => {
                console.error(err);
            });
        }
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }
}
