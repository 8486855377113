import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EmailServiceService } from 'src/app/services/Email-Service/email.service';
import { AlertService } from 'src/app/services/alertService/alertService';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactForm: FormGroup;
  public contactSubmited: boolean = false;
  constructor(
    private fb: FormBuilder,
    public emailService: EmailServiceService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      contactMessage: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  public onSendContact() {
    this.contactSubmited = true;
    this.emailService.sendContactEmailToMe(this.contactForm.value).subscribe(res => {
      this.alertService.success('¡ Mensaje de contacto Enviado !');
      this.contactSubmited = false;
    }, error => {
      /*Añadir Numero de teléfono */
      this.alertService.error('Hay un problema en este momento, intente enviarlo en unos minutos o llamenos a 3854768038');
      this.contactSubmited = false;
      this.contactForm.reset();
      console.log(error);
    });
  }


}
